.divider {
    background: #888;
    border-color: #888;
}

.resultContainer {
    margin-bottom: 30px;
}

.alert {
    color: #d6142b;
    padding: 10px 0;
}

.notice {
    font-size: 13px;
    color: #c7c7c7;
    padding: 15px 0;
    text-align: left;
}