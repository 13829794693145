.alert {
    color: #d6142b;
    padding: 20px 0;
    text-align: left;
}

.notice {
    font-size: 16px;
    line-height: 22px;
    color: #c7c7c7;
    padding: 15px 0 30px 0;
    text-align: left;
}

.inputGroup {
    text-align: left;
    padding: 10px 0;
    display: flex;
}

.label {
    flex: 1;
    padding: 10px 0;
}

.textInput {
    flex: 2;
    display: block;
    padding: 10px;
    margin: 0;
    border: none;
    outline: none;
}

.selectInput {
    flex: 2;
    display: block;
    padding: 10px;
    margin: 0;
    border: none;
    outline: none;
}

.checkboxInput {
    margin-right: 10px;
}

.buttonWrap {
    margin-top: 20px;
}