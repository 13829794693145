.button {
    display: block;
    width: 100%;
    padding: 30px;
    background: #111;
    color: #fff;
    outline: none;
    border: 1px solid #111;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 10px;
}

.buttonSelected {
    border-color: #fff;
}

.button:hover, .button:focus {
    background: #fff;
    color: #000;
}

.buttonDimmed {
    border-color: #111;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.text {

}

.textDimmed {
    
}

.image {
    width: 48px;
    height: 48px;
}