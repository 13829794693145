.button {
    display: block;
    padding: 10px;
    background: #000;
    color: #fff;
    outline: none;
    border: 1px solid #444;
    font-weight: normal;
    cursor: pointer;
    font-size: 15px;
    margin-bottom: -1px;
    text-align: left;
}

.button:hover {
    background: #fff;
    color: #000;
}

.buttonSelected {
    border-color: #fff;
    z-index: 10;
}