.button {
    display: inline-block;
    padding: 10px;
    background: #000;
    color: #fff;
    outline: none;
    border: 1px solid #444;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    font-size: 15px;
    margin-bottom: 10px;
    margin-left: 10px;
}

.button:hover, .button:focus {
    background: #fff;
    color: #000;
}

.buttonSelected {
    border-color: #fff;
}