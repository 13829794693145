.notice {
    color: #888;
    margin-bottom: 20px;
}

.roomPreview {
    background: #222;
    border-radius: 8px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    box-sizing: border-box;
}

.roomPreviewHorizontal {
    display: flex;
    padding: 0 10px;
    flex-direction: column;
}

.roomPreviewVertical {
    display: flex;
    padding: 10px 0;
    flex-direction: row;
}

.roomPreviewLine {
    background: #fff;
    border-radius: 1px;
}

.roomPreviewLineHorizontal {
    height: 4px;
    width: 100%;
}

.roomPreviewLineVertical {
    width: 4px;
    height: 100%;
}

.roomPreviewControls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 12px 0;
}

.lineControl {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.lineControlButton,
.rotateButton {
    padding: 0;
    display: inline-block;
    background: transparent;
    border: none;
    outline: none;
    color: #fff;
    cursor: pointer;
}

.lineControlButton svg,
.rotateButton svg {
    width: 20px !important;
    height: 20px !important;
}

.lineControlLabel {
    padding: 0 10px;
    font-weight: bold;
}

.rotateButton {
    flex: 0;
    flex-direction: row;
    align-items: center;
}