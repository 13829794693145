.labelContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-space-between;
    align-items: center;
    width: 100%;
}

.labelElement {
    flex: 1;
    text-align: left;
    font-size: 16px;
}

.valueContainer {
    display: flex;
    flex-direction: row;
}

.selectedValue {
    padding: 5px;
    color: #888;
    width: 80px;
}

.controlButton {
    padding: 0;
    display: inline-block;
    background: transparent;
    border: none;
    outline: none;
    color: #fff;
    cursor: pointer;
}

.controlButton svg {
    width: 20px !important;
    height: 20px !important;
}

.slider {
    padding: 30px 0 50px 0;
}

.sliderThumb {
    display: inline-block;
    cursor: move;
    margin-top: -10px;
    width: 24px;
    height: 24px;
    outline: none;
    color: #fff;
    background-color: #fff;
    text-decoration: none;
    border-radius: 20px;
    position: relative;
    white-space: nowrap;
}

.sliderTrack {
    border: 2px solid #666;
    position: relative;
}

.sliderTrack:first-child {
    border-color: #fff;
}