.notice {
    color: #888;
    margin-bottom: 20px;
}

.roomContainer {
    position: relative;
}

.room {
    background: #222;
    border-radius: 8px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    box-sizing: border-box;
}

.roomHorizontal {
    display: flex;
    padding: 0 10px;
    flex-direction: column;
}

.roomVertical {
    display: flex;
    padding: 10px 0;
    flex-direction: row;
}

.line {
    border-radius: 1px;
    display: flex;
}

.lineHorizontal {
    height: 4px;
    width: 100%;
}

.lineVertical {
    width: 4px;
    height: 100%;
}

.linePart {
    flex: 1;
}

.linePartHorizontal {
    height: 100%;
    display: flex;
    flex-direction: row;
    margin: 0 2px;
}

.linePartVertical {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 2px 0;
}

.lineFragment {
    flex: 1;
    width: 100%;
    height: 100%;
    background: #fff;
}

.lineFragmentInactive {
    background: #888;
}

.dimensionVertical {
    position: absolute;
    left: 0;
    top: 50%;
    transform: rotate(-90deg);
    margin-top: -24px;
    margin-left: -5px;
}

.dimensionLabel {
    text-align: center;
    font-size: 14px;
}