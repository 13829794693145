.titleImage {
    display: block;
    max-width: 100%;
    width: 400px;
    height: auto;
    margin: 0 auto;
    margin-top: -10px;
}

.logo {
    display: block;
    max-width: 100%;
    width: 250px;
    height: auto;
    margin: 0 auto 100px auto;
}