.item {
    text-align: left;
    padding: 10px 0;
    border-bottom: 1px solid #444;
}

.name {
    color: #bbb;
    font-size: 14px;
    letter-spacing: 1;
}

.value {
    font-size: 16px;
    padding-bottom: 5px;
}