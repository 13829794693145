.screen {
    
}

.screenHeader {
    height: 40px;
    border-bottom: 1px solid #444;
    padding: 5px 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #000;
    background: rgba(0, 0, 0, 0.9);
    z-index: 10000;
}

.screenHeaderInner {
    max-width: 800px;
    margin: 0 auto;
    display: flex;
}

.screenMain {
    padding: 20px;
    max-width: 800px;
    margin: 40px auto 0 auto;
}

.backButton {
    display: inline-block;
    padding: 10px 0;
    background: transparent;
    color: #888;
    outline: none;
    border: none;
    font-weight: bold;
    cursor: pointer;
    font-size: 15px;
    margin-bottom: 10px;
}

.backButton svg {
    padding-right: 10px;
}

.backButton:hover, .backButton:focus {
    color: #fff;
}